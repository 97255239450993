import React from 'react';
import Layout from '@/layouts/main';
import HeroView from '@/views/home/Hero';
import WhatWeDoView from '@/views/home/WhatWeDo';
import HowItWorksView from '@/views/home/HowItWorks';
import TestimonialsView from '@/views/home/Testimonials';
import NarrativeView from '@/views/home/Narrative';
// import OurTeam from '@/views/home/OurTeam';
import Blog from '@/views/home/blog';
import { Mixpanel } from '@/utils';
import WhyChooseView from '@/views/home/WhyChoose';
import SampleReport from '@/views/home/SampleReport';

export default function HomePage() {
  React.useEffect(() => Mixpanel.track(`View Home Page`), []);

  return (
    <Layout
      title="Vitract - Transforming your health through your gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
    >
      <HeroView />
      <WhatWeDoView />
      <WhyChooseView />
      <HowItWorksView />
      <SampleReport />
      {/* <OurTeam /> */}
      <TestimonialsView />
      <Blog />
      <NarrativeView />
    </Layout>
  );
}
