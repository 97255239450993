import React from 'react';
// import { BaseComponentProps } from '@/types';

type TitleProps = {
  children: string;
  className?: string;
};

export default function Title(props: TitleProps) {
  const { children, className } = props;

  return <h1 className={className}>{children}</h1>;
}

Title.defaultProps = {
  className: ``,
};
