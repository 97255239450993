import React, { useState, useEffect } from 'react';
import icon1 from '@/images/vitract1.svg';
import icon2 from '@/images/vitract2.svg';
import icon3 from '@/images/vitract3.svg';
import icon4 from '@/images/vitract4.svg';
import { useMediaQuery } from '@/hooks/useMediaQuery';

const ITEMS = [
  {
    title: `We dig deeper`,
    description: `Learn the biomarkers of your gut health.`,
    icon: icon1,
    // src: `/images/dig-deeper.gif`,
    src: [`/images/vitract01.svg`, `/images/vitract01b.svg`],
    mobileSrc: `/images/vitract01c.svg`,
  },
  {
    title: `Money saver`,
    description: `Save Money on holistic doctor visits.`,
    icon: icon2,
    src: [`/images/vitract2.png`],
  },
  {
    title: `Efficiency`,
    description: `Increase the effectiveness of therapy and medications.`,
    icon: icon3,
    src: [`/images/vitract3.png`],
  },
  {
    title: `Personalization`,
    description: `Get personalized food recommendations to optimize your gut health.`,
    icon: icon4,
    src: [`/images/vitract4.png`],
  },
].map((item, i) => ({ ...item, img: i + 1 }));

export default function WhyChoose() {
  const isAboveBelowScreens = useMediaQuery(`(max-width: 767px)`);

  const [state, setState] = useState(1);
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    let intervalId;

    if (ITEMS[state - 1].src.length > 1) {
      intervalId = setInterval(() => {
        setSlideIndex(
          (prevCounter) => (prevCounter + 1) % ITEMS[state - 1].src.length,
        );
      }, 4000);
    }

    // Clean up the timer when the component unmounts or when the timer is no longer needed.
    return () => {
      clearInterval(intervalId);
    };
  }, [ITEMS[state - 1].src]);

  return (
    <section className="md:min-h-[70vh] lg:min-h-[75vh] max-w-[1181px] mx-auto bg-white relative mt-[88px] lg:mt-[94px]">
      <h3 className="text-[24px] md:text-[48px] text-center font-[500] md:font-[700] text-[#FF713F] leading-tight">
        Why Choose Vitract
      </h3>
      <div className="flex flex-col lg:flex-row gap-x-[43px] items-center md:justify-between mt-[60px] md:mt-[95px] px-4 md:px-0">
        <div
          className="grid grid-rows-1 grid-cols-1 gap-y-[50px] md:gap-y-[20px] xs:px-[4px] md:px-[32px] w-full"
          data-aos="fade-left"
          data-aos-easing="ease-in-sine"
        >
          {ITEMS.map((item, index: number) => (
            <>
              <button
                key={item.title}
                type="button"
                onClick={() => {
                  setState(index + 1);
                  setSlideIndex(0);
                }}
                onFocus={() => {
                  setState(index + 1);
                  setSlideIndex(0);
                }}
                onMouseOver={() => {
                  setState(index + 1);
                  setSlideIndex(0);
                }}
                className={`grid grid-cols-8 md:p-[20px] gap-x-[16px] sm:first md:items-center hover:cursor-pointer text-left rounded-[4px] ${
                  index + 1 === state
                    ? ` box-shadow hover:box-shadow`
                    : `border-transparent`
                }`}
              >
                <img
                  src={item.icon}
                  alt={`Vitract - ${item.description}`}
                  className="col-span-1"
                />
                <div className="col-span-7 grid gap-y-[8px]">
                  <h4 className="text-[16px] md:text-[16px] lg:text-[20px] font-[700]">
                    {item.title}
                  </h4>
                  <p className="text-[14px] md:text-[16px] lg:text-[18px] font-[400] text-[#36405C]">
                    {item.description}
                  </p>
                </div>
              </button>

              {/* Mobile & Tab View Image */}
              <div
                className={`relative w-full md:h-[529px] h-fit md:pt-0 lg:pt-auto rounded-[10px] lg:hidden ${
                  item.img === state ? `` : `hidden`
                }`}
              >
                <img
                  src={
                    isAboveBelowScreens && item.mobileSrc
                      ? item.mobileSrc
                      : item.src[slideIndex]
                  }
                  alt="Vitract"
                  className="w-full h-full rounded-[inherit]"
                />
              </div>
            </>
          ))}
        </div>

        <div className="hidden lg:block">
          {/* {ITEMS.map(({ img, src = null }) => (
            <img
              src={src || `/images/vitract${img}.png`}
              alt="Vitract"
              className={`${
                img === state ? `` : `hidden`
              } w-[250px] md:w-[595px] md:h-[529px] h-fit  pt-[55px] md:pt-0 lg:pt-auto rounded-[10px] border border-red-500 border-solid`}
            />
          ))} */}
          <div className="relative w-[250px] md:w-[595px] md:h-[529px] h-fit pt-[55px] md:pt-0 lg:pt-auto rounded-[10px]">
            {ITEMS.map(({ img, src }) => (
              <img
                key={img}
                src={src[slideIndex]}
                alt="Vitract"
                className={`${
                  img === state ? `` : `hidden`
                } w-full h-full rounded-[inherit]`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
