import React, { useState } from 'react';
import SampleReportPopup from './popup-modal/sample-report-popup';

export default function SampleReport() {
  const [displayPopup, setDisplayPopup] = useState(false);

  const handleClick = () => {
    setDisplayPopup(true);
  };

  const handleClosePopup = () => {
    setDisplayPopup(false);
  };

  return (
    <section className="relative w-full h-full overflow-hidden">
      <div className="flex flex-col w-full h-full lg:flex-row">
        <div className="flex flex-col justify-center items-center lg:items-start lg:justify-start lg:w-[600px] xlg:w-[684px] lg:px-[32.52px] 2xlg:w-fit xl:px-[130px] bg-grunge-wall-texture bg-[#CAECCF] py-[66.46px] xl:py-[45px] 2xl:py-[65px] 2xlg:py-[60px]">
          <img
            src="/images/slider-badge2.gif"
            alt=""
            className="w-[160px] rounded-full"
          />
          <h1 className="text-[24px] leading-[32.4px] slg:text-[40px] slg:leading-[62.4px] xl:text-[60px] xl:leading-[81px] mt-[14.9px] xxs:px-[15.5px] xs:px-[35.5px] sm:px-0 font-bold text-navy-blue xxs:text-center lg:text-left">
            Want to see a sample test report?
          </h1>

          <button
            type="button"
            onClick={handleClick}
            className="flex justify-between text-[14px] leading-5 col-span-2 w-auto h-fit text-white bg-primary px-[12px] py-[10px] font-bold rounded-[4px] mt-[14.91px] text-center mx-auto lg:ml-0 hover:bg-navy-blue hover:shadow-2xl hover:scale-[1.015] transition-all duration-[0.1s] ease-in-out"
          >
            Download Test Report
          </button>

          {displayPopup && <SampleReportPopup onClose={handleClosePopup} />}
        </div>
        <div>
          <img
            src="/images/sample-report.svg"
            alt="Vitract-Sample-Report"
            className="w-full h-full"
          />
        </div>
      </div>
    </section>
  );
}
