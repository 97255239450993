import React from 'react';
import File from '@/components/svgs/File';
import Rocket from '@/components/svgs/Rocket';
import Search from '@/components/svgs/Search';
import Document from '@/components/svgs/Document';

export default function HowItWorksView() {
  return (
    <section id="hiw" className="min-h-[100vh] bg-white relative pb-[100px]">
      <div className="max-w-[1045px] w-full mx-auto md:mx-[0px] lg:mx-[60px] xl:mx-auto pt-[100px] items-center">
        <h2 className="text-[26px] pb-[40px] sm:pb-0 md:text-[40px] text-center lg:text-left font-bold text-gray-dark leading-tight">
          How It Works
        </h2>
        <div
          className="sm:max-w-[790px] sm:px-0 px-[30px] w-full h-full mx-auto grid md:grid-cols-2 gap-8 pt-[40px] md:pt-0"
          style={{ gridTemplateRows: `masonry` }}
        >
          <div
            className="mx-auto sm:w-[380px] sm:h-[380px] rounded-[8px] md:rounded-[12px] bg-tertiary sm:col-span-1 col-span-2 md:mt-[87px] sm:pb-0 pb-[40px]"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
          >
            <div className="flex justify-between items-start px-[40px] pt-[40px]">
              <span className="text-[12px] md:text-[16px] pt-[5px] md:pt-[7px] pb-[6px] md:pb-[8px] px-[15px] md:px-[22px] bg-white rounded-[4px] md:rounded-[6px] font-bold text-primary">
                Step 1
              </span>
              <Search />
            </div>
            <div className="px-[40px] mt-4">
              <h2 className="text-[36px] font-bold text-gray-dark leading-tight">
                We send you a gut test
              </h2>
              <p className="font-thin mt-[13px] text-[16px] text-[#8C8C8C]">
                Choose your preferred plan and we will ship a gut test to your
                mailing address
              </p>
            </div>
          </div>
          <div
            className="mx-auto sm:w-[380px] sm:h-[440px] rounded-[12px] bg-tertiary sm:col-span-1 col-span-2 sm:pb-0 pb-[40px] md:mt-[127px]"
            data-aos="fade-down"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="flex justify-between items-start px-[40px] pt-[40px]">
              <span className="text-[16px] pt-[7px] pb-[8px] px-[22px] bg-white rounded-[6px] font-bold text-primary">
                Step 2
              </span>
              <Document />
            </div>
            <div className="px-[40px] pb-[40px] mt-4">
              <h2 className="text-[36px] font-bold text-gray-dark leading-tight">
                Register your test kit
              </h2>
              <p className="font-thin mt-[13px] text-[16px] text-[#8C8C8C]">
                Provide your sample by following the instructions on the inner
                side of the kit. Before shipping, create an account through the
                login navigation bar on the Vitract website. Log into your
                account and register your test kit. The kit ID is found on the
                edge of the outer cover of the kit.
              </p>
            </div>
          </div>
          <div
            className="mx-auto sm:w-[380px] sm:h-[fit-content] rounded-[12px] bg-tertiary sm:col-span-1 col-span-2 pb-[40px]"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="flex justify-between items-start px-[40px] pt-[40px]">
              <span className="text-[16px] pt-[7px] pb-[8px] px-[22px] bg-white rounded-[6px] font-bold text-primary">
                Step 3
              </span>
              <File />
            </div>
            <div className="px-[40px] mt-4">
              <h2 className="text-[36px] font-bold text-gray-dark leading-tight">
                We process your result in 2 weeks
              </h2>
              <p className="font-thin mt-[13px] text-[16px] text-[#8C8C8C]">
                Using the return label enclosed within, ship your sample back to
                our lab. We will use genomic sequencing to take a snapshot of
                your gut and identify biomarkers of your gut health. You can
                access your result through your Vitract account.
              </p>
            </div>
          </div>
          <div
            className="mx-auto sm:w-[380px] sm:h-[380px] rounded-[12px] bg-tertiary sm:col-span-1 col-span-2 sm:pb-0 pb-[40px] md:mt-[30px]"
            data-aos="fade-up"
            data-aos-offset="400"
            data-aos-easing="ease-in-sine"
          >
            <div className="flex justify-between items-start px-[40px] pt-[40px]">
              <span className="text-[16px] pt-[7px] pb-[8px] px-[22px] bg-white rounded-[6px] font-bold text-primary">
                Step 4
              </span>
              <Rocket />
            </div>
            <div className="px-[40px]">
              <h2 className="text-[36px] font-bold text-gray-dark leading-tight">
                We journey
                <br />
                with you
              </h2>
              <p className="font-thin mt-[13px] text-[16px] text-[#8C8C8C]">
                Based on your gut test result, our technology generates dietary
                and lifestyle recommendations and our practitioners will help
                implement them through your membership.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
