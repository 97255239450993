import React, { MouseEvent, ReactNode } from 'react';
import { BaseComponentProps } from '@/types';

type ButtonProps = {
  className?: string;
  isSubmit?: boolean;
  text: string;
  onClick?: {
    (event: MouseEvent<HTMLButtonElement>): void;
  };
  icon?: ReactNode;
};

export default function Button(props: BaseComponentProps & ButtonProps) {
  const { icon, isSubmit, onClick, text, className } = props;

  return (
    <button
      onClick={onClick}
      type={isSubmit ? `submit` : `button`}
      className={className}
    >
      {text} {icon}
    </button>
  );
}

Button.defaultProps = {
  icon: null,
  onClick: () => null,
  isSubmit: false,
  className: `block`,
};
