import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useInView } from 'react-intersection-observer';
import NewsletterPopup from './popup-modal/newsletter-popup';

export default function TestimonialsView() {
  const TESTIMONIALS = [
    {
      author: `Laura James`,
      url: `/images/testimonial-1.png`,
      comment: `I’ve been battling depression since I was little, and it has been
            very difficult to find help`,
    },
    {
      author: `Chidozie Ojobor`,
      url: `/images/testimonial-2.png`,
      comment: `I have had anxiety since I was a child, and tried everything from therapy to medication, and always found those things to help in the short term. I was looking for a long term solution when I found nutrition as prescription and it changed my life. Now, I’m off medication and enjoy overall wellness and happiness.`,
    },
    {
      author: `Linta Mustapha`,
      url: `/images/testimonial-3.png`,
      comment: `I’ve been battling depression since I was little, and it has been
            very difficult to find help`,
    },
  ];

  const [index] = useState(1);
  // const [prev] = useState<string | null>(TESTIMONIALS[index - 1]?.url ?? null);
  // const [next] = useState<string | null>(TESTIMONIALS[index + 1]?.url ?? null);

  // function goPrev() {
  //   const pos = index - 1;
  //   setPrev(null);
  //   if (pos < 0) return;
  //   setIndex(pos);
  //   setPrev(TESTIMONIALS[pos - 1]?.url ?? null);
  //   setNext(TESTIMONIALS[pos + 1]?.url ?? null);
  // }

  // function goNext() {
  //   const pos = index + 1;
  //   if (typeof TESTIMONIALS[pos]?.author !== `string`) return;
  //   setIndex(pos);
  //   setPrev(TESTIMONIALS[pos - 1]?.url ?? null);
  //   setNext(TESTIMONIALS[pos + 1]?.url ?? null);
  // }

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [subscribed, setSubscribed] = useLocalStorage(
    `SubscribedToNewsletter`,
    `null`,
  );
  const [showPopup, setShowPopup] = useState(false);
  const [popped, setPopped] = useState(false);

  useEffect(() => {
    if (inView && !showPopup && !popped && subscribed === `null`) {
      setShowPopup(true);
      setPopped(true);
    }
  }, [inView, showPopup, popped]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <NewsletterPopup
          onClose={handleClosePopup}
          subscribed={subscribed}
          setSubscribed={setSubscribed}
        />
      )}
      <section ref={ref} className="py-[70px] md:py-[140px] w-full bg-white">
        <div className="max-w-[1068px] w-full mx-auto px-8 md:px-0 flex justify-evenly xl:justify-between gap-x-[50px] relative">
          {/* <div
          id="prev"
          className="w-[149.3px] md:w-[300px] xl:w-[450px] object-cover overflow-hidden rounded-[7.96px] md:rounded-[125px] sm:rounded-3xl absolute -left-[130px] md:-left-[400px] xl:-left-[150px]"
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
        >
          {prev && <img className="opacity-80" src={prev} alt="" />}
        </div> */}
          <div
            id="caption"
            className="w-[154px] md:w-[300px] xl:w-[365px] flex flex-col items-start h-full"
            // className="absolute left-[40px] md:left-[120px] xl:left-[385px] w-[154px] md:w-[300px] xl:w-[365px] flex flex-col items-start h-full"
          >
            <h5 className="xl:text-[30px] text-[11px] md:text-[22px] font-bold text-gray-dark-light xl:mt-[51px] mt-[5px] xl:leading-[37.35px] leading-[tight]">
              “ {TESTIMONIALS[index]?.comment} ”
            </h5>
          </div>
          {TESTIMONIALS[index]?.url && (
            <div
              id="current"
              className="w-[149.3px] md:w-[300px] xl:w-[450px] object-cover"
            >
              <img
                src={TESTIMONIALS[index]?.url}
                alt=""
                className="rounded-[125px]"
              />
            </div>
          )}
          {/* {next && (
          <div
            id="next"
            className="w-[149.3px] h-[205.36px] md:h-full md:w-[300px] xl:w-[450px] object-cover overflow-hidden rounded-[7.96px] md:rounded-[125px] sm:rounded-3xl absolute -right-[130px] md:-right-[400px] lg:-right-[150px]"
            data-aos="fade-left"
            data-aos-easing="ease-in-sine"
          >
            <img className="opacity-80" src={next} alt="" />
          </div>
        )} */}
        </div>
      </section>
    </>
  );
}
