import * as React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

function Document(props: Props) {
  const { className } = props;
  return (
    <svg
      className={className}
      width={70}
      height={72}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m68.818 37.098-10.529-10.53a3.484 3.484 0 0 0-4.927 0l-2.104 2.105V3.483A3.484 3.484 0 0 0 47.774 0H3.645A3.484 3.484 0 0 0 .161 3.484v55.742a3.484 3.484 0 0 0 3.484 3.484h19.742v5.806A3.484 3.484 0 0 0 26.87 72H37.4c.924 0 1.81-.367 2.463-1.02l28.955-28.956a3.483 3.483 0 0 0 0-4.926ZM7.128 6.968H44.29V35.64L24.407 55.523c-.07.07-.134.144-.198.22H7.13V6.967Zm28.829 58.064h-5.602V59.43l25.47-25.47 5.603 5.601-25.471 25.471Z"
        fill="#FF713F"
      />
    </svg>
  );
}

export default Document;

Document.defaultProps = {
  className: ``,
  width: 72,
  height: 72,
};
