import * as React from 'react';

type Props = {
  className?: string;
};

function File(props: Props) {
  const { className } = props;
  return (
    <svg
      className={className}
      width={58}
      height={72}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M56.657 19.16 40.87 1.075C40.252.31 39.486 0 38.573 0H3.64A3.08 3.08 0 0 0 .577 3.063v65.874A3.08 3.08 0 0 0 3.64 72h50.867c1.68 0 2.916-1.384 2.916-2.916V21.148c0-.766-.31-1.384-.766-1.988Zm-15.021-8.115 8.188 9.337h-8.188v-9.337ZM35.509 5.98v17.319a3.08 3.08 0 0 0 3.064 3.063h12.871v13.475h-9.499c-1.222 0-2.297.766-2.754 1.841l-1.075 2.298-1.84-2.607c-.457-.913-1.385-1.384-2.445-1.384h-7.658c-1.223 0-2.298.765-2.754 1.988l-.31.618-2.15-10.883c-.265-1.87-3.46-4.123-5.67-.766L9.93 42.134H6.556V5.979H35.51ZM6.704 66.036h-.148V48.26h5.214c1.222 0 2.297-.618 2.754-1.679l2.297-4.757 2.445 12.563c1.413 3.814 5.213 2.68 5.817.309l3.21-8.733h3.977l3.828 5.817c2.21 2.754 4.949.913 5.214-.31l2.445-5.522h7.657v20.073H6.704v.015Z"
        fill="#FF713F"
      />
    </svg>
  );
}

export default File;

File.defaultProps = {
  className: ``,
};
