import * as React from 'react';

type Props = {
  className?: string;
};

function Search(props: Props) {
  const { className } = props;
  return (
    <svg
      className={className}
      width={64}
      height={68}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M62.751 1.037a2.86 2.86 0 0 0-3.036-.39L.777 28.31v5.353l24.755 9.902 15.879 24.43h5.354L63.682 3.953a2.86 2.86 0 0 0-.93-2.917ZM43.46 62.89 29.811 41.891l21.002-23.004-3.323-3.034-21.167 23.183-20.41-8.164 52.515-24.65-14.969 56.667Z"
        fill="#FF713F"
      />
    </svg>
  );
}

export default Search;

Search.defaultProps = {
  className: ``,
};
