/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactNode } from 'react';

interface Props {
  onClick: () => void;
  children: ReactNode;
}

function Backdrop({ children, onClick }: Props) {
  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 z-[10000] flex items-center justify-center w-screen h-screen overflow-auto bg-[rgba(0,_0,_0,_0.7)] "
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default Backdrop;
