/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Subscribe from '@/views/newsletter';
import Backdrop from './backdrop';

interface NewsletterPopupProps {
  onClose: () => void;
  subscribed: string;
  setSubscribed: (value) => void;
  // children: React.ReactNode;
}

export default function NewsletterPopup({
  onClose,
  subscribed,
  setSubscribed,
}: NewsletterPopupProps) {
  return (
    <Backdrop onClick={onClose}>
      <div
        className="m-auto flex justify-center min-h-[400px] max-w-[400px] flex-col items-center gap-6 bg-white p-[20px] rounded"
        onClick={(e) => e.stopPropagation()}
      >
        <header className="relative w-full h-fit">
          <button
            type="button"
            onClick={onClose}
            className="relative float-right top-[0px] my-[10px] flex rounded-full transition-all duration-[0.2s] ease-in-out hover:border-0 hover:bg-white hover:shadow-md xs:right-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <h2 className="mt-12 text-lg font-bold text-[#19233F] flex justify-center items-center w-full mx-auto">
            Subscribe to our Newsletter
          </h2>
        </header>

        <div className="flex flex-col w-full h-fit">
          <img
            src="/images/newsletter.svg"
            alt=""
            className="flex justify-center mx-auto w-[150px] h-[150px]"
          />
          <h2 className="mt-10 text-lg font-medium text-[#19233F] pb-[19px]">
            Email
          </h2>

          <Subscribe
            center
            form1
            onClose={onClose}
            subscribed={subscribed}
            setSubscribed={setSubscribed}
          />
        </div>
      </div>
    </Backdrop>
  );
}
