/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import axios from 'axios';
import Airtable from 'airtable';
import Backdrop from './backdrop';

const baseAccessToken = `patuMCL69rPo6J9ww.16895e3de01700f1b0e9eb1e50b301b0e43625444914c98c18b0ee049cd922a3`;
const baseId = `appWsua0CNdU2EJ4n`;

const base = new Airtable({ apiKey: baseAccessToken }).base(baseId);

export default function SampleReportPopup({ onClose }) {
  const [email, setEmail] = useState(``);
  const [emailSent, setEmailSent] = useState<boolean | null>(null);
  const [errorInfo, setErrorInfo] = useState<string | null>(null);

  // Get User's Geolocation
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.ipgeolocation.io/ipgeo?apiKey=450b0acc3d25478caa46db2daf4f5d11`,
      );
      return response.data;
    } catch (error) {
      // console.error(`Error fetching IP geolocation:`, error);
      setErrorInfo(`Browser geolocation is not accessible.`);
      return null; // Handle the error gracefully, returning null or an appropriate value
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setErrorInfo(`Please provide an email and select a report!`);
      return;
    }
    setErrorInfo(null); // Clear any previous error message

    // Fetch geolocation data using your fetchData function
    const geolocationData = await fetchData();
    if (geolocationData === null) {
      // Handle the case where geolocation data is null
      setErrorInfo(`Browser geolocation is not accessible.`);
      return;
    }
    // Destructure properties from geolocationData only if it's not null
    const { city, state_prov, country_name } = geolocationData;

    // Make a POST request to the backend endpoint to send the email with the document link
    try {
      const payload = {
        email,
        // type: selectedReport,
        country: country_name,
      };
      const response = await axios.post(
        `https://vitractblogs.fly.dev/reports`,
        payload,
      );
      if (response.data.status === `success`) {
        setEmailSent(true);
        // Add a setTimeout to close the popup after 3 seconds
        setTimeout(() => {
          onClose();
        }, 3000);

        // Send form values to Airtable
        await base(`Sample-Report-Subscribers`).create({
          Email: email,
          City: city,
          State_Province: state_prov,
          Country: country_name,
        });
        return;
      }
      setEmailSent(false);
      // Add a setTimeout to close the popup after 3 seconds
      setTimeout(() => {
        onClose();
      }, 3000);
      return;
    } catch (error) {
      // console.log(`Failed to send email: `, error);
      setEmailSent(false);
    }
  };

  return (
    <Backdrop onClick={onClose}>
      <div
        className="m-auto flex justify-center h-auto min-w-[275px] max-w-[400px] flex-col items-center gap-5 bg-white rounded overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <header className="relative w-full h-fit xxs:px-[70.96px] xs:px-[90.96px] py-[16.27px] bg-blue-300 rounded-tl rounded-tr justify-center items-center gap-[4.79px] flex">
          <h2 className="text-slate-800 text-[20px] font-bold w-full">
            Vitract Test Report
          </h2>
        </header>

        {emailSent === null ? (
          // Render the form when emailSent state is null (initial state)
          <form onSubmit={handleFormSubmit}>
            <div className="h-[auto] w-[275px] xs:w-full px-3 bg-white flex-col justify-center items-start flex">
              <div className="flex flex-col items-start justify-start h-full gap-2">
                <p className="text-slate-800 text-[16px] font-medium">Email</p>
                <div className="h-[60px] flex-col justify-start items-start gap-2 flex ">
                  <div className="w-[250px] xs:w-[307px] h-10 relative">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Your email address"
                      value={email}
                      onChange={handleEmailChange}
                      required
                      className="xl:w-[20vw bg-[#FCFCFD] border-[0.908284px] border-solid border-[#CFD0D7] w-[250px] xs:w-[307px] h-10 left-0 top-0 absolute rounded"
                    />
                  </div>
                  {errorInfo && (
                    <div className="text-[14px] text-red-600 mt-8">
                      {errorInfo}
                    </div>
                  )}
                  <p className="text-slate-800 text-[14px] font-medium">
                    We will send the report to your email address
                  </p>
                </div>
              </div>
            </div>

            <div className="px-3 py-4 bg-white rounded-bl rounded-br justify-center items-center gap-[4.79px] flex">
              <button
                type="submit"
                // onClick={handleClick}
                className="mt-[15.85px] rounded-[3.63314px] bg-primary text-[#FFFFFF] w-[150px] h-[auto] py-[7px] px-[14px] text-center text-sm font-medium hover:shadow-2xl hover:scale-[1.015] hover:bg-navy-blue transition-all duration-[0.1s] ease-in-out"
              >
                Submit
              </button>
            </div>
          </form>
        ) : (
          // Render success or error message when emailSent state is set
          <div>
            {emailSent ? (
              // Success Message
              <div className="flex flex-col items-center justify-center">
                <img src="/images/newsletter.svg" alt="Success" />
                <p className="text-[18px] pb-5 pt-5">
                  Sample report sent successfully!
                </p>
              </div>
            ) : (
              // Error Message
              <div className="flex flex-col items-center justify-center">
                <img
                  src="/images/error.png"
                  alt="Error"
                  className="w-[30%] h-[30%] "
                />
                <p className="text-[18px] pb-5 pt-5">
                  Failed to send email. Please try again later.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </Backdrop>
  );
}
