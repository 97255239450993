import React from 'react';

export default function WhatWeDoView() {
  return (
    <section
      className="relative min-h-[1137px] md:min-h-[850px] lg:min-h-[1000px] xlg:min-h-[1050px] h-full w-full mt-[100px] overflow-hidden"
      style={{
        background: `url(/images/wwd-bg-2.svg)`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `100%`,
      }}
    >
      <div className="bg-[#19233F] z-[-1] top-[100px] sm:top-[300px] w-full h-full absolute" />
      {/* <img
        src=""
        className="sm:w-full absolute top-[70px] md:top-[0px] md:w-full w-[630px] h-[320px] sm:h-[395px] md:h-[1088px] object-cover"
        alt=""
      /> */}
      <img
        className="absolute top-0 md:-top-[40px] w-[63px] md:w-40 -left-[23px] md:left-[50px] lg:left-[134px] mx-auto -z-[2] -mt-10"
        src="/images/food-column.svg"
        data-aos="fade-right"
        alt=""
      />
      <img
        className="absolute -top-[177px] md:-top-[100px] w-[98px] md:w-40 -right-[23px] md:right-0 mx-auto -z-10 -mt-10"
        src="/images/food-column-2.svg"
        data-aos="fade-left"
        alt=""
      />
      <div
        id="wwd-content"
        className="w-full max-w-[1097px] md:mx-auto lg:w-full z-10 absolute top-[80px] md:top-[200px] xlg:top-[300px] items-center"
      >
        <h3 className="text-[24px] md:text-[48px] text-center font-[500] md:font-[700] pb-[10px] text-[#F4F1BB] leading-tight xxs:mt-10 md:mt-0">
          What we do
        </h3>
        <div className="flex flex-col items-center mx-auto md:justify-center lg:justify-between md:flex-row">
          <div>
            <img
              src="/images/wwd-1.png"
              alt="Vitract"
              className="w-[250px] md:w-[clamp(300px,_6vw,_432px)] lg:w-[432px] h-auto pt-[79px] md:pt-[50px] lg:pt-auto mx-auto"
              // width="400px"
              // height="404px"

              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
            />
            <div
              className="max-w-[450px] sm:mx-auto md:ml-[50px] xl:ml-auto sm:mt-[30px]"
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
            >
              <p className="mt-[10px] md:mt-[18px] min-w-[300px] md:w-[330px] xlg:w-full sm:px-0 text-center text-[16px] md:text-[18px] text-white font-thin">
                Vitract Gut Testing uses genomic sequencing to identify
                bacterial groups and metabolites that contribute to your
                digestive, metabolic and brain health.
              </p>
            </div>
          </div>
          <div>
            <img
              src="/images/wwd-2.png"
              alt="Vitract"
              className="w-[250px] md:w-[clamp(300px,_6vw,_432px)] lg:w-[432px] h-auto pt-[79px] md:pt-[50px] lg:pt-auto mx-auto"
              // width="400px"
              // height="404px"

              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
            />
            <div
              className="max-w-[450px] sm:mx-auto md:ml-[50px] xl:ml-auto sm:mt-[30px]"
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
            >
              <p className="mt-[10px] md:mt-[18px] min-w-[300px] md:w-[330px] xlg:w-full sm:px-0 text-center text-[16px] md:text-[18px] text-white font-thin">
                We couple gut testing with nutrition coaching which implements
                personalized suggestions from our tech to optimize your gut
                health.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
