import Button from '@/components/Button';
import React from 'react';

export default function NarrativeView() {
  return (
    <section className="bg-white pb-[100px] md:py-[100px] px-[20px] md:px-0 relative mt-9">
      <div className="lg:max-w-[1180px] min-h-[341px] mx-auto xl:mx-auto xl:h-[593px] lg:h-[550px] bg-navy-blue rounded-[10px] md:rounded-[20px] xl:rounded-[36px] pt-[49px] px-[30px] pb-[30px] xl:pt-[126px] xl:pl-[100px] xl:pb-0 relative overflow-hidden md:mx-[50px] lg:mx-[50px]">
        <div className="lg:max-w-[450px] xl:max-w-[480px] lg:w-[45%] xlg:w-full lg:py-4 xlg:py-6 xl:py-0 lg:pl-1 xlg:pl-10 xl:px-0">
          <h6 className="text-xs text-white">JOIN OUR MEMBERSHIP</h6>
          <h3 className="mt-[18px] leading-tight sm:font-medium text-secondary text-[24px] sm:text-5xl">
            We are changing
            <br className="hidden lg:block" /> the narrative
          </h3>
          <p className="sm:mt-[27px] mt-[14px] text-white font-thin sm:text-base text-[12px]">
            “With our approach, we bring evidence-based solutions buried in
            scientific literature to life. Our solution is holistic, focusing on
            the person. 85% of Vitract members have reported remarkable changes
            in their digestive health, increased energy levels, improved sleep,
            and better quality of life.”
          </p>

          <Button
            onClick={() => {
              window.location.href = `/pricing`;
            }}
            text="Join our membership"
            className="text-sm font-normal text-white border-2 border-secondary px-[20px] py-[10px] mt-[18px] rounded-[4px] flex items-center gap-x-2 hover:text-navy-blue hover:bg-secondary"
          />
        </div>
        <img
          src="/images/narrative-1.png"
          className="absolute hidden lg:block bottom-0 lg:right-[30px] xlg:right-[60px] xl:right-[60px] w-[110px] h-[154px] lg:w-auto sm:h-auto"
          alt="Become a member on Vitract"
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-anchor-placement="bottom-bottom"
        />
      </div>
      <img
        src="/images/narrative-1.png"
        className="absolute hidden bottom-0 sm:right-[100px] -right-[20px] top-[370px] w-[110px] h-[154px] sm:w-auto sm:h-auto"
        alt="Become a member on Vitract"
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-anchor-placement="bottom-bottom"
      />
    </section>
  );
}
