/* eslint-disable no-underscore-dangle */
import React from 'react';
// import { Mixpanel } from '@/utils';
// import Button from '@/components/Button';
import BLOG_POSTS from '@/data/blog.json';
import Link from '@/components/Link';
import { shortenText } from '@/utils/helpers';

export default function Blog() {
  // async function navigateToBlog() {
  //   await Mixpanel.track(`View Blog`);
  //   if (typeof window !== `undefined`) {
  //     window.open(`https://medium.com/@VitractOfficial`, `_blank`);
  //   }
  // }

  // async function navigateToBlogItem(blog: any) {
  //   await Mixpanel.track(`View Blog Content`, {
  //     title: blog.title,
  //   });
  //   if (typeof window !== `undefined`) {
  //     window.open(blog.url, `_blank`);
  //   }
  // }

  return (
    <section
      id="h-os"
      className="bg-primary-light py-8 relative md:pb-[100px] overflow-hidden"
    >
      <div className="max-w-[1180px] mx-auto md:mt-[98px] px-8 md:px-0">
        <legend className="font-medium text-[24px] sm:text-5xl leading-tight text-[#484352] md:leading-[58.7px] xlg:mx-0 md:ml-[50px]">
          What does the science say?
        </legend>

        <div className="grid w-full grid-cols-12 gap-y-12 xlg:gap-12 mt-[34px] md:mt-[80px]">
          {BLOG_POSTS.map((blog) => (
            <div
              key={blog.title}
              className="col-span-12 sm:col-span-6 xlg:mx-0 md:mx-[50px] h-auto w-auto xl:w-[580px] "
            >
              {/* <Button
                text={blog.title}
                className="font-[500] md:text-[28px] text-[24px] text-left text-[#484352] md:leading-[40px] hover:opacity-80 hover:text-primary cursor-pointer"
                onClick={() => navigateToBlogItem(blog)}
              /> */}

              <Link
                href={`/blogs/${blog._id}`}
                target="_blank"
                // className="font-[500] md:text-[28px] text-[24px] text-left text-[#484352] md:leading-[40px] hover:opacity-80 hover:text-primary cursor-pointer"
                className="w-full h-full"
              >
                {/* {blog.title} */}
                <div className="h-auto w-auto xl:w-[580px] xl:h-[350px]">
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="w-full h-auto"
                  />
                </div>
                <p className="text-[#8C8C8C] font-[300] md:text-[20px] text-[16px] md:leading-[30px] sm:mt-[10px] mt-4">
                  {shortenText(blog.excerpt, 175)}
                </p>
              </Link>
              {/* <p className="text-[#5c5a5a] md:text-base mt-[10px] flex flex-col sm:flex-row sm:justify-between sm:items-center">
                - {blog.authors}
                <span className="text-sm">{blog.date}</span>
              </p> */}
              {/* <p className="text-[#8C8C8C] font-[300] md:text-[20px] text-[16px] md:leading-[30px] sm:mt-[10px] mt-4">
                {blog.excerpt}
              </p> */}
            </div>
          ))}

          <div className="flex items-center justify-center col-span-12">
            {/* <Button
              text="Learn more on our blog"
              className="font-[500] md:text-[16px] text-[14px] text-left text-primary md:leading-[40px] bg-white cursor-pointer px-8 py-4 rounded-full hover:bg-[#FF713F] hover:text-[#FFFFFF] mt-8"
              onClick={() => navigateToBlog() as any}
            /> */}
            <Link
              href="/blogs"
              className="font-[500] md:text-[16px] text-[14px] text-left text-primary md:leading-[40px] bg-white cursor-pointer px-8 py-4 rounded-full hover:bg-[#FF713F] hover:text-[#FFFFFF] mt-8"
            >
              What does the science say
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
