import * as React from 'react';

type Props = {
  className?: string;
};

function Rocket(props: Props) {
  const { className } = props;
  return (
    <svg
      className={className}
      width={72}
      height={72}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 49.5c-4.5 3.78-6 15-6 15s11.22-1.5 15-6c2.13-2.52 2.1-6.39-.27-8.73a6.54 6.54 0 0 0-8.73-.27v0ZM36 45l-9-9a65.998 65.998 0 0 1 6-11.85A38.64 38.64 0 0 1 66 6c0 8.16-2.34 22.5-18 33a67.05 67.05 0 0 1-12 6v0Z"
        stroke="#ED6A5A"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 36H12s1.65-9.09 6-12c4.86-3.24 15 0 15 0M36 45v15s9.09-1.65 12-6c3.24-4.86 0-15 0-15"
        stroke="#ED6A5A"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Rocket;

Rocket.defaultProps = {
  className: ``,
};
